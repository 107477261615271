// import { instanceAPI } from "./index";
import { instanceAPI } from "./index";
import { authHeader } from "../_helpers";

const apiHcm = instanceAPI("HCM")
const apiHn = instanceAPI("HN")
const baseUrl = "/api/v1"
export const getInbounds = () => {
  let destination = localStorage.getItem("warehouse") || "HCM"
  let api = destination === "HCM" ? apiHcm : apiHn;
  return api.post(
    `${baseUrl}/receipts`,
    {},
    { headers: authHeader() },
  );
};

export const getInbound = (id) => {
  let destination = localStorage.getItem("warehouse") || "HCM"
  let api = destination === "HCM" ? apiHcm : apiHn;
  return api.post(
    `${baseUrl}/receipts/${id}`,
    {},
    { headers: authHeader() },
  );
};

export const getInboundByPO = (PO) => {
  const configWithRef = authHeader();
  configWithRef.ref = PO;
  let destination = localStorage.getItem("warehouse") || "HCM"
  let api = destination === "HCM" ? apiHcm : apiHn;
  return api.post(
    `${baseUrl}/receipts`,
    {},
    { headers: configWithRef },
  );
};



export const updateLotAndExdate = (PO) => {
  // const key = process.env.VUE_APP_ODOO_KEY;
  let destination = localStorage.getItem("warehouse") || "HCM"
  let api = destination === "HCM" ? apiHcm : apiHn;
  let PO_format = {
    picking_id: PO.id,
    lines: PO.lines,
  };
  return api.post(
    `${baseUrl}/receipts/update_lot_and_exdate`,
    PO_format,
    { headers: authHeader() },
  );
};

<template>
  <div>
    <b-navbar class="app-nav" toggleable="lg">
      <b-navbar-brand id="brand" href="/">thuocsi</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item id="first-item">
            <router-link to="/inbound">Inbound</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/product-search">Tìm sản phẩm</router-link>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav v-if="userInfo" class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <em>{{ userInfo.params.login }}</em>
            </template>
            <!-- <b-dropdown-item class="profile" v-if="isLoggedIn" href="#">{{
              userInfo.params.login
            }}</b-dropdown-item> -->
            <!-- <b-dropdown-item v-else href="#">Profile</b-dropdown-item> -->
            <b-dropdown-item v-if="userInfo" v-on:click="signOut"
              >Đăng xuất</b-dropdown-item
            >

            <b-dropdown-item v-on:click="goConnections">
              Kiểm tra kết nối
            </b-dropdown-item>
            <b-dropdown-item v-on:click="goLogs"> Log </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("Account");

export default {
  name: "Header",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["logout"]),
    signOut() {
      this.logout();
    },
    goConnections() {
      this.$router.push("/connections");
    },
    goLogs() {
      this.$router.push("/logs");
    },
  },
  computed: {
    ...mapGetters({ userInfo: "getUser" }),
  },
};
</script>

<style scoped>
.profile {
  font-family: Calibri;
  font-size: 15pt;
  color: black;
  font-weight: 900;
}
.navbar-nav .nav-link {
  border-radius: 5px 5px 0 0;
}
#nav-collapse {
  text-align: center;
}

/* .nav-link:has(.router-link-active) {
  background-color: #0bd577;
} */
#brand {
  font-size: 25pt;
  color: #1e266e;
  font-weight: bold;
}
</style>

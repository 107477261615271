// import axios from "axios";
import {
  getOutbounds,
  getOutboundBySO,

} from "@/api/outboundServices";
import { getOutbound } from "../../api/outboundServices";
const state = {
  ListOrder: [],
  Order: {},
  products: [],
  product: { name: "", body: "" },
  currentProduct: null,
};

const getters = {
  AllOrders: (state) => state.ListOrder,
  foundOrder: (state) => state.Order,
  allProducts: (state) => state.products,
  productById: (state) => (id) => {
    return state.products.filter((product) => product.id === id)[0];
  },
};

const actions = {
  async initializeStore({ commit, dispatch }, id) {

    if (localStorage.getItem(id)) {
      let Order = JSON.parse(localStorage.getItem(id));
      commit("setOrder", Order);
      commit("setProducts", Order.lines);
    } else {
      dispatch("fetchProducts", id).then(function () { });
    }
  },
  async fetchOrderBySO({ commit }, SO) {
    let result = null;
    try {
      result = await getOutboundBySO(SO);
    } catch (err) {
      console.log(err);
    }
    if (result && "data" in result)
      commit("setOrder", result.data.result.data.pickings);
    else alert("Response data is null");
  },
  async fetchProducts({ commit }, id) {
    await getOutbound(id).then((res) => {
      let products = res.data.result.data.receipt;
      if (Object.keys(products).length > 0) {
        products.lines.forEach(function (item) {
          item.details = [];
          item.missing = item.demand_qty;
        });
        commit("setOrder", products);
        commit("setProducts", products.lines);
        localStorage.setItem(id, JSON.stringify(state.Order));
      }
    });
  },
  async fetchListOrder({ commit }) {
    let result = null;
    try {
      result = await getOutbounds();
    } catch (err) {
      console.log("Error fetching orders: " + err);
    }
    if (result && result.data != null)
      commit("setListOrder", result.data.result.data.pickings);
    else alert("Response data is null");
  },
  // async updateSO(_, id) {},
};

const mutations = {
  setProducts: (state, products) => {
    state.Order.lines = products;
    state.products = products;
  },
  setOrder: (state, order) => (state.Order = order),
  setProduct: (state, product) => (state.product = product),
  setCurrentProduct: (state, currentProduct) =>
    (state.currentProduct = currentProduct),
  setListOrder: (state, Orders) => (state.ListOrder = Orders),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from "vue";
import VueRouter from "vue-router";
import PO from "../components/Inbound/PO.vue";
import SO from "../components/Outbound/SO.vue";
import outProducts from "../components/Outbound/outProducts.vue";
import inProducts from "../components/Inbound/inProducts.vue";
import Login from "../components/Authentication/Login.vue";
import NotFoundPage from "../components/Outbound/NotFoundPage.vue";
import Products from "../components/Product/Products.vue"
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: NotFoundPage,
  },
  {
    path: "/outbound",
    name: "SO",
    component: SO,
  },
  {
    path: "/outbound/:id",
    name: "OutProducts",
    // component: () => import("../components/Outbound/outProducts.vue"),
    component: outProducts,
  },
  {
    path: "/inbound",
    name: "PO",
    component: PO,
  },
  {
    path: "/inbound/:id",
    name: "InProducts",
    component: inProducts,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/product-search",
    name: "ProductSearch",
    component: Products,
  },
  {
    path: "/connections",
    name: "Connections",
    component: () => import("../components/Connections"),
  },
  {
    path: "/logs",
    names: "Logs",
    component: () => import("../components/Log.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  if (authRequired && !loggedIn) {
    alert("Please login first");
    return next("/");
  }
  return next();
});
export default router;

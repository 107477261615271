export function authHeader() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.params.token) {
    const returnHeader = {
      'TOKEN': user.params.token,
      'Api-Key': user.params.key
    }
    return returnHeader;
  } else {
    return null;
  }
}

<template>
  <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
    <div
      v-if="validationSuccess"
      @click="turnCameraOn"
      class="validation-success"
    >
      Nhấn vào đây để quét mã QRCode
    </div>

    <div v-if="validationFailure" class="validation-failure">
      Mã không đúng định dạng!
    </div>

    <div v-if="validationPending" class="validation-pending">Đang xử lý..</div>
  </qrcode-stream>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { QrcodeStream },
  // props: ["camera"],
  data() {
    return {
      camera: "auto",
      isValid: undefined,
      result: null,
    };
  },

  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },

    validationSuccess() {
      return this.isValid === true;
    },

    validationFailure() {
      return this.isValid === false;
    },
  },

  methods: {
    onInit(promise) {
      promise
        .catch((e) => {
          switch (e.name) {
            case "NotAllowedError":
              alert("Cần cấp quyền truy cập camera");
              break;
            case "NotReadableError":
              alert("Thiết bị không hỗ trợ hoặc camera đang sử dụng");
              break;
            case "StreamApiNotSupportedError":
              alert("Trình duyệt chưa hỗ trợ ");
              break;
            default:
              break;
          }
        })
        .then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.turnCameraOff();
      this.isValid = undefined;
      // pretend it's taking really long
      await this.timeout(200);
      this.isValid = content.startsWith("{");

      // some more delay, so users have time to read the message
      // await this.timeout(200);

      if (!this.isValid) this.turnCameraOn();
      else this.$emit("scan-done", content);
    },

    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
};
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>
import { logAPI } from "./index"

const baseUrl = "warehouse/core/v1";
// const key = "Basic UEFSVE5FUi9lcnB2MTphcWl3bnNjMTIzcHdqc2xocjQ1NnBvaXJsNzg5";
const key = process.env.VUE_APP_V2_KEY;
export const postLog = (data) => {
    const config = {
        headers: {
            "Authorization": key,
        },
    }
    // return data;
    return logAPI.post(`${baseUrl}/audit-log`, data, config);
}
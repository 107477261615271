<template>
  <div id="app" class="container">
    <b-row class="mb-3">
      <h1 style="color: #0bd577">Purchase Orders</h1>
      <b-col class="text-right ml-auto">
        <b-dropdown
          variant="primary"
          size="sm"
          id="dropdown_settings"
          :text="currentActivePrinter"
          class="m-2"
        >
          <b-dropdown-header> <h5>Máy in</h5></b-dropdown-header>
          <div v-for="item in printerOptions" :key="item.id">
            <b-dropdown-item-button
              v-on:click="setActivePrinter(item.id)"
              ref="PrinterOptions"
            >
              {{ item.label }}
            </b-dropdown-item-button>
          </div>
        </b-dropdown>
      </b-col>
    </b-row>

    <div v-if="alert.message" :class="`alert ${alert.type}`">
      {{ alert.message }}
    </div>

    <!-- Tables -->
    <b-tabs pills content-class="mt-3">
      <b-tab title-item-class="text-danger" title="Phiếu đang kiểm" active>
        <vue-good-table
          class="pb-4"
          id="ongoing"
          theme="my-theme"
          v-if="onGoingOrders"
          :columns="fields"
          :row-style-class="rowStyleClassFn"
          styleClass="vgt-table"
          :rows="onGoingOrders"
          @on-row-click="onRowClick"
          :search-options="{
            enabled: true,
            placeholder: 'Tìm phiếu ',
          }"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'po_status'">
              <span>
                {{
                  props.row.status && props.row.status == 1
                    ? "Đã cập nhật"
                    : "Chưa cập nhật"
                }}
              </span>
            </span>
            <span v-if="props.column.field == 'print'">
              <span>
                {{ getDoneQty(props.row) }} &sol; {{ getTotalQty(props.row) }}
              </span>
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <template slot="table-column" slot-scope="props">
            <span>
              <span class="custom-header">
                {{ props.column.label }}
              </span>
            </span>
          </template>
        </vue-good-table>
      </b-tab>
      <b-tab title="Phiếu đã cập nhật">
        <b-row class="mx-auto">
          <b-col class="text-right ml-auto" style="font-style: italic"
            >Chỉ hiện 100 phiếu gần nhất</b-col
          >
        </b-row>
        <vue-good-table
          class="pb-4"
          theme="my-theme"
          v-if="updatedOrders"
          :columns="fields"
          :row-style-class="rowStyleClassFn"
          styleClass="vgt-table"
          :rows="updatedOrders"
          @on-row-click="onRowClick"
          :search-options="{
            enabled: true,
            placeholder: 'Tìm Phiếu ',
          }"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'po_status'">
              <span>
                {{
                  props.row.status && props.row.status == 1
                    ? "Đã cập nhật"
                    : "Chưa cập nhật"
                }}
              </span>
            </span>
            <span v-if="props.column.field == 'print'">
              <span>
                {{ getDoneQty(props.row) }} &sol; {{ getTotalQty(props.row) }}
              </span>
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <template slot="table-column" slot-scope="props">
            <span>
              <span class="custom-header">
                {{ props.column.label }}
              </span>
            </span>
          </template>
        </vue-good-table>
      </b-tab>
      <template #tabs-end>
        <li style="width: 100vh" role="presentation" class="pl-4 nav-item">
          <b-input-group>
            <b-input
              autofocus
              @keyup.enter="searchPO"
              id="search-input"
              v-model.trim="search"
              class="dropdown-input"
              type="text"
              placeholder="Nhập mã phiếu/PO"
            />
            <b-input-group-append>
              <b-button @click="searchPO" variant="info"
                >Tìm phiếu mới</b-button
              >
            </b-input-group-append>
          </b-input-group>

          <div class="theme-container" v-if="!apiLoaded">
            <div class="text-center h1 py-5 text-black-50">
              <img
                src="@/assets/images/loading.gif"
                alt="Loading..."
                class="loading"
              />
            </div>
          </div>

          <!-- dropdown list -->
          <div
            style="position: absolute; z-index: 2"
            v-show="search && apiLoaded && searchOrder"
            class="dropdown-list"
            id="dropdown"
          >
            <div
              @click="onRowClick(item)"
              v-for="(item, index) in searchOrder"
              :key="item.ref"
              :id="'receipt-' + index"
              :class="{
                'dropdown-item': true,
                'd-flex': true,
                'justify-content-between': true,
                'align-items-center': true,
                active: index === current_select_receipt_index,
              }"
            >
              <div>
                <h4>{{ item.name }}</h4>
                <span>{{ item.ref }}</span>
              </div>
              <span
                ><i>{{ item.date }}</i>
              </span>
            </div>
          </div>
        </li>
      </template>
    </b-tabs>
  </div>
</template>

<script>
// import {mapActions, mapState, mapGetters} from "vue"
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("Inbound");
const AlertHelpers = createNamespacedHelpers("Alert");
export default {
  data() {
    return {
      current_select_receipt_index: 0,
      printerOptions: [
        { id: 0, label: "1. In 2 máy" },
        { id: 1, label: "2. In máy 1" },
        { id: 2, label: "3. In máy 2 " },
      ],
      currentActivePrinter: "1. In 2 máy",
      Orders: [],
      apiLoaded: true,
      searchOrder: [],
      fields: [
        { field: "id", label: "ID", width: "100px" },
        { field: "partner", label: "Partner", width: "200px" },
        { field: "name", label: "Tên phiếu", width: "150px" },
        {
          field: "ref",
          label: "Reference",
          width: "120px",
        },
        { field: "date", label: "Ngày", width: "150px" },
        { field: "print", label: "Đã in", width: "120px" },
        { field: "po_status", label: "Tình trạng", width: "150px" },
        { field: "startedAt", label: "Bắt đầu", width: "100px" },
        { field: "finishedAt", label: "Kết thúc", width: "100px" },
      ],
      search: "",
    };
  },
  mounted() {
    if (!localStorage.getItem("PO")) {
      let username = JSON.parse(localStorage.getItem("user")).params.login;
      // console.log(username);
      localStorage.setItem(
        "PO",
        JSON.stringify({ user: username, detail: [] })
      );
    } else {
      let SOList = JSON.parse(localStorage.getItem("PO"));
      // console.log(SOList);
      SOList.detail.forEach((SO) => {
        let SODetail = JSON.parse(localStorage.getItem(SO));
        // console.log(SODetail);
        this.Orders.push(SODetail);
      });
    }
    if (!localStorage.getItem("active_printer")) {
      localStorage.setItem("active_printer", 0);
    } else {
      let current_printer_id = localStorage.getItem("active_printer");
      this.setActivePrinter(current_printer_id);
    }
  },
  methods: {
    ...mapActions([
      "fetchProducts",
      "fetchPO",
      "fetchListOrder",
      "fetchOrderByPO",
    ]),
    ...AlertHelpers.mapActions(["clear"]),
    setActivePrinter(id) {
      localStorage.setItem("active_printer", id);
      this.currentActivePrinter = this.printerOptions[id].label;
    },
    rowStyleClassFn(row) {
      return row.status && row.status == 1 ? "po_green" : "red";
    },
    searchPO() {
      this.searchOrder = [];
      if (this.search != "") {
        // console.log("------Order----------", this.searchOrder);
        this.clear();
        this.apiLoaded = false;
        this.fetchOrderByPO(this.search).then(() => {
          this.apiLoaded = true;
          if (this.foundOrder.length > 0) {
            this.searchOrder = this.foundOrder;
            this.$nextTick(() => {
              this.setupNavigationOnDropdown();
            });
          }
        });
      }
      // this.error("Vui lòng nhập mã phiếu hoặc mã PO cần tìm", { root: true });
      else alert("Vui lòng nhập mã phiếu PO cần tìm");
    },

    setupNavigationOnDropdown() {
      //unfocus search bar
      document.getElementById("search-input").blur();
      const dropdown_list = document.getElementById("dropdown");
      const children = document.getElementById("dropdown").childNodes;
      children.forEach((child, index) => {
        child.tabIndex = index;
      });
      dropdown_list.addEventListener(
        "keydown",
        this.handleArrowKeyPressOnDropdown
      );

      children[0].focus();
    },
    handleArrowKeyPressOnDropdown(event) {
      //check which key is pressed
      if (event.key === "ArrowDown") {
        this.current_select_receipt_index += 1;
        if (this.current_select_receipt_index > this.searchOrder.length - 1)
          this.current_select_receipt_index -= 1;
      } else if (event.key === "ArrowUp") {
        this.current_select_receipt_index -= 1;
        if (this.current_select_receipt_index < 0)
          this.current_select_receipt_index = 0;
      } else if (event.key === "Enter") {
        this.onRowClick(this.searchOrder[this.current_select_receipt_index]);
      }
      const nextChild = document.getElementById("dropdown").childNodes[
        this.current_select_receipt_index
      ];
      nextChild.focus();
    },
    updatePOList(newPOID) {
      let copySOList = JSON.parse(localStorage.getItem("PO"));
      copySOList.detail.push(newPOID);
      localStorage.setItem("PO", JSON.stringify(copySOList));
    },
    getDoneQty(row) {
      let po_id = row.id;
      try {
        let copyPO = JSON.parse(localStorage.getItem(po_id));
        if (copyPO) {
          return copyPO.lines.reduce((prev, next) => prev + next.done_qty, 0);
        }
        return 0;
      } catch (e) {
        console.log(e);
      }
    },
    getTotalQty(row) {
      let po_id = row.id;
      try {
        let copyPO = JSON.parse(localStorage.getItem(po_id));
        if (copyPO) {
          let total = copyPO.lines.reduce(
            (prev, next) => prev + next.demand_qty,
            0
          );
          return total;
        }
        return 0;
      } catch (e) {
        console.log(e);
      }
    },
    onRowClick(param) {
      //check which element calls the function:  table row or dropdown list
      let search_val = "";
      search_val = "row" in param ? param.row.id : param.id;
      // this.$router.push({ name: "OutProducts", params: { id: this.search } });
      //if not from row (user click from result list)
      // push Product to ongoing table
      if (!("row" in param)) {
        search_val = param.id;
        let isOrderExisted = this.Orders.findIndex((O) => {
          return parseInt(O.id) === parseInt(search_val);
        });
        if (isOrderExisted == -1) {
          this.Orders.push(param);
          //Push current receipts to lS PO list
          // this.updateSOList(param.id);
          this.$router.push({
            name: "InProducts",
            params: { id: search_val },
          });
        } else alert("Phiếu này đã tồn tại trong bảng");
      } //If click from row, change to product page
      else
        this.$router.push({ name: "InProducts", params: { id: search_val } });
    },
    getPO() {
      if (this.search != "") {
        this.fetchPO(this.search).then(() => {
          let poID = this.foundOrder[0].id;
          this.$router.push("/" + poID);
        });
      } else alert("Vui lòng nhập PO");
    },
    updateItem(id) {
      //update current product
      this.product = this.productById(id);
    },
    checkPOStatus(row) {
      let id = row.id;
      let POList = JSON.parse(localStorage.getItem(id));
      // let PO = POList.filter((po) => po.id == id);
      return POList.status && POList.status == 1
        ? "Đã cập nhật"
        : "Chưa cập nhật";
    },
  },
  computed: {
    ...mapGetters(["foundOrder", "AllOrders"]),
    ...AlertHelpers.mapState({ alert: (state) => state }),
    onGoingOrders: function () {
      let ongoingPO = this.Orders.filter(
        (order) =>
          !Object.prototype.hasOwnProperty.call(order, "status") ||
          order.status == 0
      );
      ongoingPO = ongoingPO.reverse();
      let limit_po = ongoingPO.slice(0, 100);
      localStorage.setItem("ongoingPO", JSON.stringify(ongoingPO));
      return limit_po;
    },

    updatedOrders: function () {
      let updatedPO = this.Orders.filter(
        (order) =>
          Object.prototype.hasOwnProperty.call(order, "status") &&
          order.status == 1
      );
      updatedPO = updatedPO.reverse();
      let limit_po = updatedPO.slice(0, 100);
      localStorage.setItem("updatedPO", JSON.stringify(limit_po));
      return limit_po;
    },
  },
};
</script>

<style lang="scss">
#searchLabel {
  top: 18px;
  left: 6px;
  position: relative;
  background-color: white;
  font-size: 0.9em;
}
.po_red {
  color: #f67980;
}

.po_green {
  & td {
    background-color: #f3fdf8;
    font-style: italic;
    & span {
      color: #0bd577;
    }
  }
}
.green {
  color: grey;
  background-color: #f3fdf8;
}

.blue {
  background-color: #404564;
}

.blue > td > span {
  font-size: 15pt;
  color: white;
}
#dropdown_settings__BV_toggle_ {
  font-size: 12pt;
}

.purple {
  background-color: #4e75ff !important;
}
#dropdown_settings {
  max-width: 80px;
}
.navbar-nav .nav-link {
  border-radius: 5px 5px 0 0;
}
.dropdown {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 10px auto;
}

.dropdown-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}

.dropdown-list {
  width: 99vh;
  max-height: 300px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.dropdown-item:active {
  outline: none !important;
  background-color: #4e75ff !important;
}
.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.custom-header {
  font-size: 1em;
}
.custom-cell {
  font-family: "Calibri";
  font-size: 1.5em;
  color: #0bd577;
}

#app {
  margin-left: 30px;
  margin-right: 30px;
  min-width: 90vw;
}
</style>

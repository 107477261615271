import Vue from "vue";
import VueResources from "vue-resource";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueGoodTablePlugin from "vue-good-table";

// import the styles
import "vue-good-table/dist/vue-good-table.css";

//Boostrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {BootstrapVue,BootstrapVueIcons} from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.use(VueResources);
Vue.use(VueGoodTablePlugin);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


// store.subscribe((_, state) => {
//   let Order = state.Outbound.Order;
//   console.log(Order);
//   if (Object.keys(Order).length != 0) {
//     let order_detail = Order;
//     // console.log(order_detail);
//     localStorage.setItem(Order.id, JSON.stringify(order_detail));
//   }
//   console.log("Calling state from subber", state.Outbound);
// });

<template>
  <div v-if="Object.keys(pdetail).length > 0" class="ItemDetail">
    <div>
      <b-card
        :title="pdetail.name"
        :img-src="
          pdetail.image_url
            ? pdetail.image_url
            : 'https://assets.thuocsi.vn/assets/defaults/missing-e9cfa4812c342b9780b61700d2ade43591b3c5992f4dffedaa542c07d854b602.png'
        "
        :img-alt="pdetail.name"
        img-top
        class="mb-2"
      ></b-card>
      <b-list-group class="Detail">
        <b-list-group-item
          v-for="detail in pdetail.details"
          :key="detail.lot"
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <h6 class="text-capitalize">
              lô&colon;
              <span>{{ detail.lot }}</span>
            </h6>
            <h6 class="text-capitalize">
              Hết hạn&colon;&nbsp;Tháng
              <span>{{ formatDate(detail.ex_date) }}</span>
            </h6>
          </div>
          <b-badge variant="primary" pill>
            <span>Số lượng&colon;&nbsp;</span>
            <span>{{ detail.done_qty }}</span>
          </b-badge>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("Outbound");
import { mask } from "vue-the-mask";
export default {
  name: "TodoItem",
  props: ["pdetail"],
  directives: {
    mask,
  },
  data() {
    return {
      msg: {},

      productInfo: [],
    };
  },
  mounted() {
    if (localStorage.getItem(this.$route.params.id)) {
      let product = JSON.parse(
        localStorage.getItem(this.$route.params.id)
      ).lines.filter((p) => p.id === this.pdetail.id);
      if (Object.prototype.hasOwnProperty.call(product, "details")) {
        this.actualProductDetails = product.details;
      }
    }
  },
  computed: mapGetters(["foundOrder", "productById"]),
  methods: {
    ...mapActions(["updateProductDoneQuantity"]),
    formatDate(date) {
      let split_index;
      if (date.length == 5 || date.length == 6) {
        split_index = date.length - 4;
        return (
          date.substring(0, split_index) + " / " + date.substring(split_index)
        );
      } else {
        split_index = date.length - 2;
        return (
          date.substring(0, split_index) + " / " + date.substring(split_index)
        );
      }
    },
    updateLocalStorage() {
      if (localStorage.getItem(this.$route.params.id)) {
        let copyOrder = this.foundOrder;
        copyOrder.lines = this.actualProductDetail;
        localStorage.setItem(this.$route.params.id, JSON.stringify(copyOrder));
      }
    },
  },
};
</script>

<style scoped>
.list-group-item {
  padding: 10px 10px;
}
.Detail {
  max-height: 300px;
  overflow: auto;
}
.badge {
  padding: 9px;
  margin-top: -10px;
  font-size: 15px;
}
.card {
  /* background-color: aquamarine; */
  margin-top: 10px;
  max-width: 100%;
}
.card img {
  height: 280px;
  width: 280px;
  overflow: auto;
  max-width: 100%;
  /* max-width: 150px; */
  margin-left: auto;
  margin-right: auto;
}
.info-group {
  margin-left: 20px;
  margin-top: auto;
}
.info-group-img {
  border-radius: 5px;
  height: 200px;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.info-group-img img {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.info-row {
  font-size: 15pt;
  margin-left: 0px;
  margin-right: 0px;
}

.col {
  padding: 0;
  width: auto;
}
input {
  border: 1px transparent;
  border-radius: 5px;
  margin: 0px;
}

button {
  height: 50px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}
.info-group input,
button,
label {
  margin-bottom: 10px;
}
</style>

<template>
  <div v-if="Object.keys(pdetail).length > 0" class="ItemDetail">
    <div>
      <b-card
        :title="pdetail.display_name"
        :img-src="
          pdetail.image_url
            ? pdetail.image_url
            : 'https://assets.thuocsi.vn/assets/defaults/missing-e9cfa4812c342b9780b61700d2ade43591b3c5992f4dffedaa542c07d854b602.png'
        "
        :img-alt="pdetail.display_name"
        img-top
        class="mb-2"
      ></b-card>
      <b-table
        ref="info_table"
        light
        hover
        no-provider-filtering
        v-show="pdetail.details"
        :items="pdetail.details"
        @row-clicked="getDetails"
      >
      </b-table>
      <b-row class="info-row mt-4">
        <b-col class="col" cols="3">
          <span class="label">ERP ID&colon;&nbsp;</span>
        </b-col>
        <b-col class="col">
          <span class="chip">{{ pdetail.id }}</span>
        </b-col>
      </b-row>
      <b-row class="info-row mt-4">
        <b-col class="col mr-2" cols="3">
          <span class="label">Location&colon;&nbsp;</span>
        </b-col>
        <b-col class="col">
          <span class="chip">
            {{
              pdetail.location
                ? pdetail.location
                : pdetail.product_location_str
                ? pdetail.product_location_str
                : "Chưa có "
            }}
          </span>
        </b-col>
      </b-row>

      <b-row class="info-row mt-4">
        <b-col class="col" sm="2">
          <span class="label">Lô SX&colon;&nbsp;</span>
        </b-col>
        <b-col sm="3">
          <input
            class="text-uppercase"
            maxlength="15"
            v-model="lot"
            autofocus
          />
        </b-col>
      </b-row>
      <b-row class="info-row mt-4">
        <b-col class="col" sm="2">
          <span class="label">HSD&colon;&nbsp;</span>
        </b-col>

        <b-col sm="9">
          <b-input-group id="year-input">
            <b-input-group-prepend class="mr-2">
              <b-input-group-text>01</b-input-group-text>
            </b-input-group-prepend>
            <span class="slash">&sol;</span>
            <b-form-input
              placeholder="Tháng"
              v-model="month"
              :disabled="false"
              class="mx-2"
              type="text"
              maxlength="2"
            ></b-form-input>
            <span class="slash">&sol;</span>
            <b-input-group-prepend class="ml-2">
              <b-input-group-text>20</b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              required
              placeholder="Năm"
              v-model="year"
              id="year-2dg"
              :disabled="false"
              type="text"
              maxlength="2"
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="info-row">
        <b-col sm="2">
          <span class="invalid" v-if="msg.date">{{ msg.date }}</span>
        </b-col>
      </b-row>
      <!-- Qty and utilities -->
      <b-row class="info-row" align-v="center">
        <b-col class="col" sm="2">
          <span>Số lượng&colon;&nbsp;</span>
        </b-col>
        <b-col sm="3">
          <b-input
            class="input mt-3"
            v-model="qty"
            placeholder="Nhập số lượng"
          />
        </b-col>
        <b-col sm="3">
          <b-button
            variant="outline-info"
            class="QRCodeBtn"
            v-on:click="UpdateLotExp"
          >
            Cập nhật
          </b-button>
        </b-col>
        <b-col sm="3">
          <b-dropdown right split :text="modes[selected_mode - 1].name">
            <b-dropdown-item
              @click="setSelected(mode.id)"
              v-for="mode in modes"
              :key="mode.id"
              >{{ mode.name }}</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center">
        <b-col sm="4">
          <b-button
            variant="outline-info"
            class="QRCodeBtn"
            v-on:click="printQRCode"
            >{{ txtPrintAction }}
          </b-button>
        </b-col>
        <span> Hoặc</span>
        <b-col sm="4">
          <b-button
            :disabled="this.isPrint"
            variant="outline-success"
            :class="QRCodeBtn"
            class="QRCodeBtn"
            v-on:click="printQRCodeWithSensor"
            >{{ txtPrintWithSensor }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import { printQuantity, printWithSensor } from "@/api/printerServices.js";
export default {
  name: "ProductDetail",
  props: ["pdetail"],
  data: function () {
    return {
      selected_mode: 1,
      modes: [
        {
          id: 1,
          name: "In máy 1",
        },
        {
          id: 2,
          name: "In máy 2",
        },
        {
          id: 3,
          name: "In 2 máy (chỉ in băng chuyền)",
        },
      ],
      qty: 0,
      msg: {},
      isPrint: false,
      month: "",
      year: "",
      lot: "",
      // date: "",
      productInfo: [],
      QRCodeBtn: "outline-info",
      txtPrintAction: "In SLL",
      txtPrintWithSensor: "In băng chuyền",
      hasDetail: false,
      details: [],
    };
  },
  created() {
    if (localStorage.getItem("selected_printer"))
      this.selected_mode = JSON.parse(localStorage.getItem("selected_printer"));
    if (localStorage.getItem(this.pdetail.id)) {
      // console.log("found item");
      this.pdetail.details = JSON.parse(
        localStorage.getItem(this.pdetail.id)
      ).details;
    } else {
      this.pdetail.details = [];
      localStorage.setItem(this.pdetail.id, JSON.stringify(this.pdetail));
    }
  },

  methods: {
    setSelected(id) {
      this.selected_mode = id;
      localStorage.setItem("selected_printer", this.selected_mode);
    },
    getDetails(row, index) {
      let info = this.pdetail.details[index];
      this.lot = info.lot;
      this.month = info.ex_date.split("-")[1];
      this.year = info.ex_date.split("-")[0].slice(-2);
    },
    checkDetailsExist() {
      if (localStorage.getItem(this.pdetail.id)) {
        let product = JSON.parse(localStorage.getItem(this.pdetail.id));
        if ("details" in product) return true;
      }
      return false;
    },

    UpdateLotExp() {
      if (!(this.month || this.year || this.lot))
        alert("Vui lòng nhập thông tin lot/ date");
      else {
        if (!this.checkLotDateExist()) this.updateLocalStorage();
        else alert("Lô & date này đã tồn tại ");
        // this.$emit("print", this.isPrint);
      }
      this.$refs.info_table.refresh();
    },
    updateLocalStorage() {
      this.pdetail.details.push({
        lot: this.lot,
        ex_date: this.date,
        qty: this.qty,
      });

      localStorage.setItem(this.pdetail.id, JSON.stringify(this.pdetail));
      // makeToast(
      //   this,
      //   "success",
      //   `Đã cập nhật: ${Products[productIndex].name}`
      // );
    },
    formatDate(date) {
      return date.substring(5) + "/" + date.substring(0, 4);
    },
    checkLotDateExist() {
      let details = JSON.parse(localStorage.getItem(this.pdetail.id)).details;
      var found_index = -1;
      let found_detail = details.filter((detail, index) => {
        found_index = index;
        return detail.lot == this.lot && detail.ex_date == this.date;
      });
      // console.log(found_detail.length, found_index);
      if (found_index == -1 || found_detail.length == 0) return false;
      else {
        // alert("Lô Date này đã tồn tại");
        return true;
      }
    },

    createPrintInfo() {
      let month = "";
      let year = "";
      if (this.month != "" && this.year != "") {
        month = this.month;
        // year = "20" + this.year  ;
        year = this.year;
      }
      return {
        //important!!!!!
        prid: this.pdetail.id,
        poid: 0,
        lot: this.lot,
        ex_date: month + year,
        v: 0,
      };
    },
    async printQRCodeWithSensor() {
      if (!(this.month || this.year || this.lot))
        alert("Vui lòng nhập thông tin lot/ date");
      else {
        if (!this.checkLotDateExist()) this.updateLocalStorage();
        let print_info = this.createPrintInfo();
        // console.log(JSON.stringify(print_info));
        // const self = this;
        await printWithSensor(print_info, this.qty).then(
          (res) => {
            if (res.data) {
              this.QRCodeBtn = "btn-outline-danger";
              this.txtPrintWithSensor = "Dừng in";
              // makeToast(this, "success", `Đang in ${this.pdetail.name}`, true);
              this.isPrint = !this.isPrint;
            }
          }
          // (error) => makeToast(this, "error", `Xảy ra lỗi: ${error}`)
        );

        // }
      }
    },
    async printQRCode() {
      if (!(this.month || this.year || this.lot))
        alert("Vui lòng nhập thông tin lot/ date");
      else {
        if (!this.checkLotDateExist()) this.updateLocalStorage();
        let print_info = this.createPrintInfo();
        // console.log(JSON.stringify(print_info));
        await printQuantity(print_info, this.qty).then(
          (res) => {
            if (res.data) {
              // this.QRCodeBtn = "btn-outline-danger";
              // this.txtPrintAction = "Dừng in";
              // makeToast(
              //   this,
              //   "success",
              //   `Đang in ${this.pdetail.name}`,
              //   true
              // );
              this.isPrint = !this.isPrint;
            }
          }
          // (error) => makeToast(this, "error", `Xảy ra lỗi: ${error}`)
        );

        // }
      }
    },
  },
  computed: {
    date: function () {
      if (this.month && this.year) {
        let result = "20" + this.year + "-" + this.month + "-" + "01";
        return result;
      }
      return "";
    },
  },
  watch: {
    date: function () {
      let dt = new Date();
      let currentYear = dt.getFullYear() % 100;
      if (this.year > 20) {
        if (this.month > 12 || this.month < 0 || this.year < currentYear) {
          this.msg.date = "Ngày hết hạn không hợp lệ";
        } else if (this.year == currentYear && this.month < dt.getMonth() + 1) {
          this.msg.date = "Tháng hết hạn không hợp lệ";
        } else this.msg.date = "";
      } else this.msg.date = "Năm hết hạn không hợp lệ";
    },
  },
};
</script>

<style scoped>
.chip {
  background-color: #17a2b8;
  border-radius: 5px;
  padding: 6px;
  color: white;
}
.input-btn {
  margin-top: 15px;
  height: 50px;
}
.QRCodeQtyBtn {
  margin-top: 20px;
  min-width: 100%;
}
.QRCodeBtn {
  margin-top: 20px;
  min-width: 100%;
}
.list-group-item {
  padding: 10px 10px;
}
.Detail {
  max-height: 300px;
  overflow: auto;
}
.badge {
  padding: 9px;
  margin-top: -10px;
  font-size: 15px;
}
.card {
  /* background-color: aquamarine; */
  margin-top: 10px;
  max-width: 100%;
}
.card img {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
}
.info-group {
  margin-left: 20px;
  margin-top: auto;
}
.info-group-img {
  border-radius: 5px;
  height: 200px;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.info-group-img img {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.info-row {
  font-size: 15pt;
  margin-left: 0px;
  margin-right: 0px;
}

.col {
  padding: 0;
  width: auto;
}
input {
  border: 1px transparent;
  border-radius: 5px;
  margin: 0px;
}

button {
  height: 50px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}
.info-group input,
button,
label {
  margin-bottom: 10px;
}
.invalid {
  margin-top: 100px;
  color: red;
}
</style>

// import config from "config";

import { loginRequest } from "../api/AccountServices";
import { logoutRequest } from "../api/AccountServices";
export const userService = {
  login,
  logout,
};

function login(username, password) {
  let auth = {
    params: {
      login: username,
      password: password,
    },
  };
  return (
    loginRequest(auth)
      // .then(handleResponse)
      .then((res) => {
        // console.log(res);
        if (res.data.result.data.token) {
          auth.params.token = res.data.result.data.token;
          auth.params.key = res.data.result.data.key;
          delete auth.params.password;
          localStorage.setItem("user", JSON.stringify(auth));
        } else {
          throw "Login information is not correct!";
        }
        return auth;
      })
  );
}

async function logout() {
  //TODO: call actual API
  localStorage.removeItem("user");
  return await logoutRequest().then((res) => {
    return res.data.result.status;
  });
}

import { authHeader } from "../_helpers";
import { instanceAPI } from "./index";
const baseUrl = "/api/v1"
export const getOutbounds = () => {
  return instanceAPI.post(
    `${baseUrl}/pickings`,
    {},
    { headers: { TOKEN: authHeader() } }
  );
};
export const getOutboundBySO = (SO) => {
  return instanceAPI.post(
    `${baseUrl}/pickings`,
    {},
    { headers: { TOKEN: authHeader(), ref: SO } }
  );
};
export const getOutbound = (id) => {
  return instanceAPI.post(
    `${baseUrl}/pickings/${id}`,
    {},
    { headers: { TOKEN: authHeader() } }
  );
};

export const updateLotAndExdate = (SO) => {
  // console.log("SO from updateLotAndExDate", SO);
  return instanceAPI.post(
    `${baseUrl}/pickings/update_lot_and_exdate`,
    { params: SO },
    { headers: { TOKEN: authHeader() } }
  );
};

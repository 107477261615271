<template>
  <div v-if="Object.keys(pdetail).length > 0" class="ItemDetail">
    <div id="ProductItem">
      <div class="info-group-img">
        <img
          :src="
            pdetail.image_url
              ? pdetail.image_url
              : 'https://assets.thuocsi.vn/assets/defaults/missing-e9cfa4812c342b9780b61700d2ade43591b3c5992f4dffedaa542c07d854b602.png'
          "
          :alt="pdetail.name"
        />
      </div>

      <div class="info-group">
        <!-- Product Name -->

        <b-row class="info-row">
          <h3 id="product_name" class="text-capitalize">{{ pdetail.name }}</h3>
        </b-row>
        <b-row class="info-row">
          <span class="mr-2">Số lượng&colon;&nbsp;</span>
          <span class="content">{{ pdetail.demand_qty }}</span>
        </b-row>
        <!-- Lot Input -->
        <b-row class="info-row align-items-center">
          <b-col class="col" sm="3">
            <span class="label">Lô sản xuất&colon;&nbsp;</span>
          </b-col>
          <b-col sm="9">
            <input
              id="lot_input"
              class="text-uppercase"
              maxlength="15"
              v-model="lot"
              autofocus
            />
          </b-col>
        </b-row>

        <!-- Date Input -->

        <b-row class="info-row align-items-center">
          <b-col class="col" sm="3">
            <span class="label">Ngày hết hạn&colon;&nbsp;</span>
          </b-col>
          <b-col sm="9">
            <b-input-group id="date-input">
              <b-input-group-prepend class="mr-2">
                <b-input-group-text>01</b-input-group-text>
              </b-input-group-prepend>
              <span class="slash">&sol;</span>
              <b-form-input
                id="month_input"
                placeholder="Tháng"
                v-model="month"
                :disabled="false"
                class="mx-2"
                type="text"
                maxlength="2"
              ></b-form-input>
              <span class="slash">&sol;</span>
              <b-input-group-prepend class="ml-2">
                <b-input-group-text>20</b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                required
                placeholder="Năm"
                v-model="year"
                id="year_input"
                :disabled="false"
                type="text"
                maxlength="2"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <!-- valiDate -->
        <b-row class="info-row">
          <b-col sm="4"></b-col>
          <b-col sm="8">
            <span class="invalid" v-if="msg.date">{{ msg.date }}</span>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center" align-v="center">
          <b-col cols="6">
            <b-button
              :variant="QRCodeBtn"
              :disabled="!isPrint"
              class="QRCodeBtn mr-0"
              v-on:click="printQRCode"
              >{{ txtPrintAction }}
            </b-button>
          </b-col>
          <b-col cols="6" class="pa-0">
            <b-button
              variant="info"
              class="QRCodeBtn ml-0"
              v-on:click="UpdateLotExp"
            >
              Cập nhật Lô, HSD
            </b-button>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center" align-v="center">
          <b-col cols="6" class="text-right ma-0 pa-0">
            <input
              v-model="print_qty"
              style="max-width: 70%"
              placeholder="Nhập số lượng"
            />
          </b-col>
          <b-col cols="6" class="justify-content-md-end">
            <b-button
              v-b-popover.hover.bottom.html="{
                variant: 'warning',
                html: true,
                content: '<b>Không cộng dồn số lượng vô sản phẩm</b>',
              }"
              @click="printQty"
              id="print_qty_btn"
              variant="info"
              class="QRCodeBtn ml-0"
              >In hàng loạt
            </b-button>
          </b-col>
        </b-row>

        <!-- <b-row align-v="center" align-h="center">
          <b-col align-self="center" cols="12">
            <b-input-group>
              <b-input style="max-width: 100px"></b-input>
              <b-input-group-append> </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { createNamespacedHelpers } from "vuex";
// import { mapActions, mapGetters } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("Inbound");
import { printWithSensor } from "@/api/printerServices";
import { makeToast } from "../Message/index";
import { printQuantity } from "../../api/printerServices";
export default {
  name: "TodoItem",
  props: ["pdetail"],
  data() {
    return {
      msg: {},
      isPrint: true,
      month: "",
      year: "",
      lot: "",
      // date: "",
      isUpdatingCount: false,
      productInfo: [],
      QRCodeBtn: "info",
      txtPrintAction: "In QR Code",
      hasDetail: false,
      print_qty: null,
    };
  },
  mounted() {
    if (this.isUpdatingCount)
      setInterval(
        function () {
          this.loadData();
        }.bind(this),
        30000
      );

    if (localStorage.getItem(this.$route.params.id)) {
      let product = JSON.parse(
        localStorage.getItem(this.$route.params.id)
      ).lines.find((p) => p.id === this.pdetail.id);
      let isLotAndDate =
        Object.prototype.hasOwnProperty.call(product, "ex_date") &&
        Object.prototype.hasOwnProperty.call(product, "lot");
      if (isLotAndDate) {
        if (product.ex_date != "") {
          [this.year, this.month, ,] = product.ex_date.split("-");
          this.year = this.year.slice(2);
          this.date = product.ex_date;
        }
        this.lot = product.lot;
        if (this.lot != "" || this.date != "") this.hasDetail = true;
      }
    }
    this.setFocusOnLotInput();
    this.addDateInputListener();
  },
  beforeDestroy() {
    this.removeDateInputListener();
  },
  computed: {
    ...mapGetters(["foundOrder", "productById"]),
    date: function () {
      if (this.month && this.year) {
        let result = "20" + this.year + "-" + this.month + "-" + "01";
        return result;
      }
      return "";
    },
  },

  watch: {
    date: function () {
      let dt = new Date();
      let currentYear = dt.getFullYear() % 100;
      if (this.year > 10) {
        if (this.month > 12 || this.month < 0 || this.year < currentYear) {
          this.msg.date = "Ngày hết hạn Không hợp lệ";
        } else if (this.year == currentYear && this.month < dt.getMonth() + 1) {
          this.msg.date = "Tháng hết hạn không hợp lệ";
        } else this.msg.date = "";
      } else this.msg.date = "Năm hết hạn không hợp lệ";
    },
  },
  methods: {
    ...mapActions(["updateDoneQuantity", "updatePreviousProductQuantity"]),
    printQty() {
      if (!(this.month || this.year || this.lot))
        alert("Vui lòng nhập thông tin lot/ date");
      if (this.print_qty) {
        if (parseInt(localStorage.getItem("active_printer")) === 0)
          alert("Chỉ in được 1 máy, vui lòng chọn lại máy in");
        else {
          const string_to_print = this.createInfoToPrint();
          printQuantity(string_to_print, this.print_qty)
            .then(() => {
              makeToast(
                this,
                "success",
                `Đang in ${this.pdetail.name}: ${this.print_qty} sp`
              );
            })
            .catch((error) => {
              makeToast(this, "danger", `Xảy ra lỗi: ${error}`);
            });
        }
      } else alert("Vui lòng nhập số lượng");
      this.$emit("print-done", this.isPrint);
    },
    setFocusOnLotInput() {
      document.getElementById("lot_input").focus();
    },
    addDateInputListener() {
      const monthInput = document.getElementById("month_input");
      monthInput.addEventListener("keyup", this.handleMaximumChars);
    },
    handleMaximumChars(e) {
      if (this.month.length == 2) {
        e.target.blur();
        document.getElementById("year_input").focus();
      }
    },
    removeDateInputListener() {
      // const monthInput = document.getElementById("month_input");
      // console.log(monthInput);
      window.removeEventListener("keyup", this.handleMaximumChars);
    },
    setPrintStopStatus() {
      this.isPrint = !this.isPrint;
      this.isUpdatingCount = !this.isUpdatingCount;
      let url = "http://localhost:8080/PrintBarcode/";
      axios.get(url, {
        params: {
          string: "",
          quantity: 1,
          posX: 30,
          posY: 30,
        },
      });
      this.QRCodeBtn = "disable btn-info";
      this.txtPrintAction = "In QR Code";
      // console.log("Stopped");
      // this.updateDoneQuantity(this.pdetail);
      // this.updateLocalStorage();
    },
    UpdateLotExp() {
      const validate =
        this.month.length > 0 && this.year.length > 0 && this.lot.length > 0;
      console.log(validate);
      if (!validate) {
        alert("Vui lòng nhập thông tin lot/ date");
      } else {
        console.log(this.month.length, this.year.length, this.lot.length);
        this.updateLocalStorage();
        // emit event to set focus on search bar
        this.$emit("action-done", this.isPrint);
      }
    },
    createInfoToPrint() {
      let month = "";
      let year = "";
      if (this.month != "" && this.year != "") {
        month = this.month;
        year = this.year;
      }
      return {
        prid: this.pdetail.product_id,
        poid: this.$route.params.id,
        lot: this.lot,
        ex_date: month + year,
        v: this.pdetail.vat,
      };
    },
    async printQRCode() {
      if (!(this.month || this.year || this.lot))
        alert("Vui lòng nhập thông tin lot/ date");
      else {
        // if (!this.isPrint) this.setPrintStopStatus();
        // else {
        await this.updatePreviousProductQuantity(this.pdetail);
        this.updateLocalStorage();

        let infoAsString = this.createInfoToPrint();
        console.log(JSON.stringify(infoAsString));
        // const self = this;
        await printWithSensor(infoAsString).then(
          (res) => {
            if (res.data) {
              this.QRCodeBtn = "outline-success";
              this.txtPrintAction = "Đang in sản phẩm";
              makeToast(this, "success", `Đang in ${this.pdetail.name}`);
              this.isPrint = !this.isPrint;
              this.$emit("print-done", this.pdetail.id);
            }
          },
          (error) => makeToast(this, "error", `Xảy ra lỗi: ${error}`)
        );

        // }
        // }
      }
    },
    updateLocalStorage() {
      // if (this.date !== "" && this.lot !== "")
      if (localStorage.getItem(this.$route.params.id)) {
        let copyOrder = this.foundOrder;
        let Products = copyOrder.lines;
        let productIndex = Products.findIndex((p) => {
          return p.id === this.pdetail.id;
        });
        Products[productIndex].lot = this.lot != "" ? this.lot : "";
        Products[productIndex].ex_date = this.date;
        copyOrder.lines = Products;
        localStorage.setItem(this.$route.params.id, JSON.stringify(copyOrder));
        makeToast(
          this,
          "success",
          `Đã cập nhật: ${Products[productIndex].name}`
        );
      }
    },
  },
};
</script>

<style scoped>
#product_name {
  /* min-height: 80px; */
}
.slash {
  font-size: 20px;
}
.QRCodeBtn {
  margin-top: 20px;
  /* padding: 5; */
  min-width: 70%;
}

.invalid {
  color: red;
}
.info-group {
  margin-left: 20px;
  margin-top: auto;
}
#ProductItem {
  padding-top: 5px;
  border-radius: 05px;
  min-height: 70vh;
  background-color: #e6e8ef;
}
.info-group-img {
  margin: 10px;
  border-radius: 5px;
  height: 200px;
  background: white;
  margin-top: 10px;
}
.info-group-img img {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.info-row {
  font-size: 12pt;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
}

.col {
  padding: 0;
  width: auto;
}
input {
  border: 1px transparent;
  border-radius: 5px;
  margin: 0px;
}

input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #ced4da;
  /* background: #e9ecef; */
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}
button {
  height: 50px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}
#date-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#year_input {
  width: 20px;
}
.info-group,
button {
  margin-bottom: 10px;
}
</style>

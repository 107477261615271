<template>
  <div class="ItemDetail">
    <div>
      <b-card
        :img-src="require('@/assets/alert.png')"
        img-top
        class="mb-2 card-main"
      >
        <b-card-body class="card-title">
          <h1>404</h1>
          <h5>Page not found</h5>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

h1 {
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 80px;
}
h5 {
  text-align: center;
  font-family: "Nunito", sans-serif;
}
.ItemDetail {
}
.card-body {
  padding: 5px;
  color: #fff;
}
.card {
  padding-top: 80px;
  min-height: 80vh;
  border: transparent;
  background-color: #404564;
}
.card img {
  margin-top: 20px;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
}
.info-row {
  font-size: 15pt;
  margin-left: 0px;
  margin-right: 0px;
}

.col {
  padding: 0;
  width: auto;
}
</style>

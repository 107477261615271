/* eslint-disable no-unused-vars */
<template>
  <b-container fluid>
    <b-row
      v-if="selected_mode == 1 && camera != 'off'"
      class="align-items-center justify-content-center"
    >
      <!-- <b-col align-self="center" cols="3">
        <ScanQRCode v-on:scan-done="searchWithScannedString"></ScanQRCode>
      </b-col> -->

      <b-collapse :visible="collapse_visible" id="collapse-1">
        <div id="canvas">
          <ScanQRCode v-on:scan-done="searchWithScannedString"></ScanQRCode>
        </div>
      </b-collapse>
    </b-row>
    <!-- <b-btn @click="testOpenPhoneCamera">Scan QRCode</b-btn> -->
    <div class="dropdown">
      <b-row align-h="center" align-v="center">
        <b-col cols="6">
          <b-input
            id="true-input"
            autofocus
            @keyup.enter="searchProduct"
            ref="dropdowninput"
            v-model.trim="search"
            class="dropdown-input"
            type="text"
            placeholder="Nhập và nhấn Enter để tìm kiếm"
          />
        </b-col>
        <b-col cols="3">
          <b-dropdown
            @click="searchProduct"
            right
            split
            :text="selected_mode ? modes[selected_mode].name : modes[0].name"
          >
            <b-dropdown-item
              @click="setModeSelected(mode.id)"
              v-for="mode in modes"
              :key="mode.id"
              >{{ mode.name }}</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </b-row>
    </div>
    <b-row id="Container" align-h="center" align-content="center">
      <b-col class="theme-container" v-if="!apiLoaded">
        <div class="text-center h1 py-5 text-black-50">
          <img
            src="../../assets/images/loading.gif"
            alt="Loading..."
            class="loading"
          />
        </div>
      </b-col>
      <b-col v-else-if="found" sm="7" v-show="products">
        <b-table
          id="main_table"
          max-height="500px"
          :fields="fields"
          light
          hover
          :busy="isFetching"
          :per-page="perPage"
          sticky-header="800px"
          :items="currentPageItems"
          @row-clicked="fetchAndShowDetails"
          selectable
          select-mode="single"
          :tbody-tr-class="rowClass"
        >
          <template #cell(index)="data">
            {{ (currentPage - 1) * perPage + data.index + 1 }}
          </template>

          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(image_url)="data">
            <img
              v-show="data.value"
              :src="data.value"
              alt=""
              width="80px"
              height="80px"
            />
          </template>
        </b-table>
        <b-row align-h="center">
          <b-btn v-if="currentPage > 1" @click="goPrevious" class="mr-4">
            Trước</b-btn
          >
          <b-btn
            v-if="parseInt(currentPage) < parseInt(totalPages)"
            @click="goNext"
            >Tiếp</b-btn
          >
        </b-row>
      </b-col>
      <b-col class="theme-container" v-else>
        <div class="text-center h1 py-5 text-black-50">
          <img
            src="../../assets/not_found.png"
            alt="not-found"
            class="not_found"
          />
          <h1 class="text-uppercase">xảy ra lỗi</h1>
          <h4>{{ error_msg }}</h4>
        </div>
      </b-col>
      <b-col ref="productDetail" id="ProductItem" sm="5">
        <div v-if="selected.id > 0">
          <ProductDetail :pdetail="selected" :key="selected.id"></ProductDetail>
        </div>
        <div v-else-if="isFetchingDetails">
          <div class="text-center h1 py-5 text-black-50">
            <img
              src="../../assets/images/loading.gif"
              alt="Loading..."
              class="loading"
            />
          </div>
        </div>
        <div v-else-if="error_msg.length > 0">
          <div class="text-center h1 py-5 text-black-50">
            <img
              src="../../assets/not_found.png"
              alt="not-found"
              class="not_found"
            />
            <h1 class="text-uppercase">xảy ra lỗi</h1>
            <h4>{{ error_msg }}</h4>
          </div>
        </div>
        <div v-else>
          <b-row class="text-center">
            <b-col class="mt-4">
              <h1 class="green-item">Chọn một sản phẩm để xem chi tiết</h1>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import { getProductsByName, getProductsById } from "../API/searchService";
import ScanQRCode from "../../components/ScanQRCode/ScanQRCode";
import {
  getProductsByName,
  getProductByAdminId,
  getProductsById,
} from "../../api/searchService";
// import {
//   searchProducts,
//   // searchProductDetail,
// } from "../../api/elasticSearchServices";
import ProductDetail from "./ProductDetail";

export default {
  name: "Home",
  components: {
    ProductDetail,
    ScanQRCode,
  },
  data() {
    return {
      camera: "off",
      collapse_visible: false,
      error_msg: "",
      totalPages: 0,
      currentPage: 1,
      perPage: 0,
      filter: null,
      isFetching: false,
      isFetchingDetails: false,
      apiLoaded: true,
      found: true,
      selected_mode: 0,
      modes: [
        { id: 0, name: "Tìm theo tên" },
        { id: 1, name: "Quét QRCode" },
      ],
      selected: {},
      fields: [
        "STT",
        // A column that needs custom formatting
        // { key: "id", label: "ERP ID" },
        // A regular column
        { key: "admin_id", label: "Admin ID" },
        { key: "display_name", label: "Tên sản phẩm" },
        // A virtual column made up from two fields
        { key: "image_url", label: "Hình ảnh" },
      ],
      search: "",
      products: [],
      history_products: [],
      search_text: "",
    };
  },
  methods: {
    scrollToView() {
      const tbody = this.$refs.productDetail;
      // .querySelector("div");
      console.log(tbody);
      tbody.scrollIntoView();
      // this.selectedIndex = index;
    },
    searchWithScannedString(string) {
      this.search = string;
      this.searchProduct();
      this.scrollToView();
    },
    goPrevious() {
      this.currentPage -= 1;
    },
    async goNext() {
      this.isFetching = true;

      if (this.currentPage < this.totalPages) {
        // check if we have already fetched new products

        if (this.products.length <= this.currentPage * this.perPage) {
          let res = await this.fetchNextPage();
          if (res.data) {
            let fetched_data = res.data.data;
            this.products = this.products.concat(fetched_data);
          }
        } else this.currentPage += 1;
        this.isFetching = false;
      }
    },

    setModeSelected(id) {
      this.selected_mode = id;
    },
    rowClass(item, type) {
      if (type == "row") return "green-item";
    },
    onSelected(item) {
      return item;
    },
    showDetails(row) {
      this.selected = row;
    },

    async fetchAndShowDetails(row) {
      //Check if product has already been fetched and stored in localStorage
      let prd = localStorage.getItem(row.id)
        ? JSON.parse(localStorage.getItem(row.id))
        : null;
      //fetch if can't find product in localStorage
      if (prd == null) {
        prd = row;
        try {
          this.isFetchingDetails = true;
          let res = await getProductByAdminId(row.admin_id);
          if (res && res.data) {
            let data = res.data.data;
            prd.id = data[0].id;
            prd.location = data[0].product_location_str;
            this.selected = prd;
          }
        } catch (err) {
          this.error_msg = err.response
            ? err.response.statusText
              ? err.response.statusText
              : err.response.data
            : err;
        }
      } else this.selected = prd;
      this.isFetchingDetails = false;
    },
    async searchProduct() {
      if (this.search.length == 0) {
        alert("Vui lòng nhập từ khóa tìm kiếm");
        return;
      }

      //clear existing products before fetching new products
      this.products = [];
      this.apiLoaded = false;
      this.found = false;
      switch (parseInt(this.selected_mode)) {
        case 0: {
          try {
            const res = await getProductsByName(this.search);
            this.apiLoaded = true;

            if (res.data && res.data.data) {
              this.found = true;
              this.products = res.data.data;
            } else this.error_msg = "Không tìm thấy sản phẩm";
          } catch (e) {
            this.apiLoaded = true;
            this.error_msg = e.response ? e.response.data.messages : e;
          }

          break;
        }
        //search by ID | Scan QR Code
        case 1: {
          this.products = [];
          let id;
          // check if user input is id or JSON object
          if (Number.isInteger(parseInt(this.search)))
            id = parseInt(this.search);
          else {
            let json_search = JSON.parse(this.search);
            id = json_search.prid;
          }
          if (!id) throw new Error("Bạn chưa nhập ID hoặc ID không hợp lệ");
          try {
            let res = await getProductsById(id);
            this.apiLoaded = true;
            //if request sent ok
            if (res && res.data.data) {
              //if product  found
              if (res.data.data[0] != null) {
                this.products = res.data.data;
                this.found = true;
                this.showDetails(this.products[0]);
              } else {
                this.error_msg = "Không tìm thấy sản phẩm ";
                return;
              }
            }
          } catch (e) {
            this.apiLoaded = true;
            console.log(e);
            this.error_msg = e.response ? e.response.data.messages : e;
          }
          break;
        }
        default: {
          alert("wrong mode");

          break;
        }
      }
      this.search = "";
    },
  },
  computed: {
    currentPageItems() {
      let first_item_index =
        parseInt(this.currentPage - 1) * parseInt(this.perPage) + 1; // page 1: 0*10+1 = 11
      let current_page_items = this.products.slice(
        first_item_index - 1,
        first_item_index + 10
      );

      return current_page_items;
    },
  },
  watch: {
    selected_mode() {
      if (this.selected_mode == 1) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            navigator.userAgent
          )
        ) {
          this.collapse_visible = true;
          this.camera = "auto";
        } else {
          this.collapse_visible = false;
        }
      } else {
        this.collapse_visible = false;
      }
    },
    collapse_visible() {
      if (this.collapse_visible == false) this.camera = "off";
    },
  },
};
</script>
<style scoped>
#canvas {
  /* background-color: rebeccapurple; */
  width: 349px;
  height: 262px;
}
#Container {
  overflow: hidden;
  /* background-color: #e6e8ef; */
  margin-left: 0;
  margin-right: 0;
}
.green-item {
  color: #0bd577;
}
#dropdown_settings__BV_toggle_ {
  font-size: 12pt;
}
#dropdown_settings {
  max-width: 80px;
}
navbar-nav .nav-link {
  border-radius: 5px 5px 0 0;
}
.dropdown {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 10px auto;
}

.dropdown-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 5px;
}

.dropdown-list {
  width: 100%;
  max-height: 300px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.dropdown-item:hover {
  /* background-color: #b6f2d6; */
}
.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}
#ProductItem {
  border-radius: 05px;
  min-height: 70vh;
  background-color: #e6e8ef;
}
</style>

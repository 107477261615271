import { instanceAPI } from "./index";
const apiHcm = instanceAPI("HCM")
const apiHn = instanceAPI("HN")
const baseUrl = "/api/v1";
export const loginRequest = (auth) => {
  let destination = localStorage.getItem("warehouse") || "HCM"
  let api = destination === "HCM" ? apiHcm : apiHn;
  console.log(api);
  // console.log("config", `${process.env.VUE_APP_CONFIG_URL}`);
  return api.post(`${baseUrl}/login`, auth);
};

export const logoutRequest = () => {
  let destination = localStorage.getItem("warehouse") || "HCM"
  let api = destination === "HCM" ? apiHcm : apiHn;
  return api.post(`${baseUrl}/logout`, {});
};

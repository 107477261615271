import Vuex from "vuex";
import Vue from "vue";
import { Inbound } from "./modules/Inbound";
import Outbound from "./modules/Outbound";
import { Alert } from "./modules/Alert";
import { Account } from "./modules/Account";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    Inbound,
    Outbound,
    Account,
    Alert,
  },
});

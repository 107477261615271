import axios from "axios";
// const destination = localStorage.getItem("warehouse");

export const instanceAPI = (destination) => {
  return axios.create({
    // baseURL: `${destination === "hn" ? process.env.VUE_APP_API_ERP_HANOI : process.env.VUE_APP_API_ERP_HCM}`,
    baseURL: `${destination === "HN" ? process.env.VUE_APP_API_ERP_HANOI : process.env.VUE_APP_API_ERP_HCM}`,
  });
}


export const searchAPI = axios.create({
  baseURL: `${process.env.VUE_APP_LOG_API_URL}`,
});
// export const searchAPI = axios.create({
//   baseURL: `${process.env.VUE_APP_ADMIN_API_URL}`,
// });
export const printerAPI = axios.create({
  baseURL: process.env.VUE_APP_BASE_PRINTER_URL,
  timeout: 10000,
});

export const logAPI = axios.create({
  baseURL: `${process.env.VUE_APP_LOG_API_URL}`,
  timeout: 10000,
});
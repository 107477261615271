<template>
  <div id="Todos">
    <audio class="audio-success">
      <source src="https://pic.pikbest.com/00/36/92/75v888piC8dG.mp3" />
    </audio>
    <audio class="audio-error">
      <source src="https://pic.pikbest.com/00/55/72/94H888piCuiy.mp3" />
    </audio>
    <b-row id="Container" class="d-flex align-items-start">
      <b-col class="product-table" sm="7">
        <!-- input template -->
        <!-- 2379 sữa rửa mặt cetaphil (c/125ml);130945;2149608;122022;2222222222 -->
        <!-- 2379 sữa rửa mặt cetaphil (c/125ml);130945;2149609;122022;2222222222 -->
        <!-- 2149609 -->
        <div>
          <h1>{{ foundOrder.name }}</h1>
          <h4 class="lead">{{ foundOrder.ref }}</h4>
        </div>
        <b-row class="align-items-center mb-3">
          <b-col sm="8">
            <b-input
              id="true-input"
              v-model="search"
              type="text"
              autofocus
              placeholder="Quét QRCode tại đây"
              @keyup.enter="updateDetail"
            />
          </b-col>
          <b-col>
            <b-button id="modal-btn" variant="warning" v-b-modal.modal-1
              >Xem danh sách</b-button
            >
          </b-col>
        </b-row>

        <b-modal size="lg" id="modal-1" title="Danh sách thiếu/ trả ">
          <div>
            <b-tabs content-class="mt-3">
              <b-tab title="DS thiếu hàng" active
                ><b-table
                  hover
                  small
                  dark
                  borderless
                  class="missing-list"
                  :items="getMissingProducts()"
                  :fields="columns"
                  :head-variant="headVariants"
                  :table-variant="tableVariants"
                ></b-table
              ></b-tab>
              <b-tab title="DS trả hàng">
                <b-table
                  hover
                  small
                  dark
                  borderless
                  :items="notFoundList"
                  :fields="notFoundListColumns"
                ></b-table>
              </b-tab>
            </b-tabs>
          </div>
          <template v-slot:modal-footer="{ ok }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button variant="primary" @click="print()">
              In danh sách
            </b-button>
            <b-button variant="success" @click="ok()"> Đóng </b-button>
            <!-- Button with custom close trigger value -->
          </template>
        </b-modal>

        <!-- <input
          v-model="searchTerm"
          type="text"
          placeholder="ID sản phẩm cần tìm"
          @keyup.enter="updateDetail"
        /> -->
        <vue-good-table
          ref="table"
          theme="my-theme"
          v-if="foundOrder.lines"
          :columns="fields"
          :rows="foundOrder.lines"
          :row-style-class="rowStyleClassFn"
          max-height="300px"
          @on-row-click="onRowClick"
          :line-numbers="true"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actual_qty'">{{
              actualQuantity(props.row.id)
            }}</span>
            <span v-if="props.column.field == 'missing'">{{
              props.row.missing
            }}</span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </vue-good-table>
      </b-col>

      <b-col id="ProductItem" v-if="product.id" sm="5">
        <component :is="hasFound" v-bind="getProps"></component>
      </b-col>
    </b-row>
    <b-button v-on:click="finishUp" id="outFinishBtn" variant="success"
      >Hoàn thành SO</b-button
    >
    <b-row></b-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("Outbound");
// import { updateLotAndExdate } from "../../api/outboundServices";
import ProductDetail from "./outProductDetail";
import NotFound from "./NotFound";
export default {
  name: "Products",
  components: {
    NotFound,
    ProductDetail,
  },
  created() {
    this.initializeStore(this.$route.params.id);
    //create details array for each product
  },
  mounted() {
    this.addListener();
  },
  beforeDestroy() {
    this.removeListener();
  },
  data() {
    return {
      notFoundList: [],
      headVariants: "light",
      tableVariants: "danger",
      columns: [
        {
          key: "product_id",
          label: "ID",
        },
        {
          key: "name",
          label: "Tên sản phẩm",
        },
        {
          key: "demand_qty",
          label: "Số lượng",
        },
        {
          key: "done_qty",
          label: "Đã quét",
        },
        {
          key: "missing",
          label: "Thiếu",
        },
      ],

      fields: [
        { field: "product_id", label: "ID", type: "number" },
        { field: "name", label: "Tên sản phẩm" },
        { field: "demand_qty", label: "Số lượng", type: "number" },
        { field: "actual_qty", label: "Đã quét", type: "number" },
        {
          field: "missing",
          label: "Thiếu",
          type: "number",
          sortable: true,
          firstSortType: "desc",
        },
      ],
      search: "",
      searchTerm: "",
      product: {},
      products: [],
      found: true,
      selectedIndex: null,
    };
  },
  methods: {
    ...mapActions(["initializeStore", "fetchProducts", "updateSO"]),
    scrollToRow(index) {
      const tbody = this.$refs.table.$el.querySelector("tbody");
      const row = tbody.querySelectorAll("tr")[index];
      row.scrollIntoView();
      this.selectedIndex = index;
    },
    addListener() {
      document.addEventListener("keypress", this.handleKeyPress);
      document.addEventListener("keydown", this.handleArrowKeyPress);
    },
    removeListener() {
      document.removeEventListener("keypress", this.handleKeyPress);
      document.removeEventListener("keydown", this.handleArrowKeyPress);
    },
    handleArrowKeyPress(e) {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        let totalItems = this.foundOrder.lines.length;
        switch (e.key) {
          case "ArrowDown":
            if (this.selectedIndex < totalItems - 1) {
              this.selectedIndex += 1;
              this.product = this.foundOrder.lines[this.selectedIndex];
              this.scrollToRow(this.selectedIndex);
            }
            break;
          case "ArrowUp":
            totalItems = this.foundOrder.lines.length;
            if (this.selectedIndex > 0) {
              this.selectedIndex -= 1;
              this.product = this.foundOrder.lines[this.selectedIndex];
              this.scrollToRow(this.selectedIndex);
            }
            break;
          default:
            break;
        }
      }
    },
    handleKeyPress(e) {
      if (e.target.tagName !== "INPUT") {
        let input = document.querySelector("#true-input");
        input.focus();
        input.value = e.key;
        e.preventDefault();
      }
    },
    reverseFormatDate(date) {
      if (date.length == 3) return date.substring(1) + "/";
      return date.substring(2) + "/" + date.substring(0, 2);
    },
    print() {
      let active_tab = document.getElementsByClassName("tab-pane active")[0];
      let active_list = active_tab.childNodes[0];
      let newWin = window.open("");
      newWin.document.write(active_list.outerHTML);
      newWin.print();
    },
    getMissingProducts() {
      if (this.foundOrder.lines)
        return this.foundOrder.lines.filter((p) => p.missing > 0);
    },

    makeToast(variant, message) {
      let title = variant == "success" ? "Success" : "Error";
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },

    updateSO() {
      const { id, ref } = this.foundOrder;
      const lines_to_update = this.foundOrder.lines;
      lines_to_update.forEach((item) => {
        delete item.done_qty;
        delete item.missing;
        delete item.uom;
        delete item.image_url;
      });
      const SO_update = {
        picking_id: id,
        ref: ref,
        lines: lines_to_update,
      };
      console.log(SO_update);

      /* WARNING: Call api to update SO to ERP */
      // updateLotAndExdate(SO_update).then((res) => {
      //   let data = {};
      //   if (res && res.data) {
      //     data = res.data.result;
      //     if (data.meta.status) {
      //       this.makeToast("success", data.meta.message);
      //     } else this.makeToast("danger", data.meta.message);
      //   } else this.makeToast("danger", "đã xảy ra lỗi");
      // });
    },
    finishUp() {
      //update last item
      this.updateSO();
      // this.$router.push("/Outbound");
      // console.log("Done");
    },

    onRowClick(params) {
      this.found = true;
      this.selectedIndex = params.pageIndex;
      this.product = this.allProducts.filter(
        (product) => product.id == params.row.id
      )[0];
    },
    rowStyleClassFn(row) {
      if (this.selectedIndex == row.originalIndex) return "purple";
      return row.missing ? "red" : "green";
    },

    updateDetail() {
      let successAudio = document.getElementsByClassName("audio-success")[0];
      console.log(successAudio);
      // let errorAudio = document.getElementsByClassName("audio-error")[0];
      if (this.search != "" || this.searchTerm != "") {
        try {
          let detail = JSON.parse(this.search);
          //product_id , PO id, lot, date, vat

          // convert to array to independent from keys
          const value = Object.values(detail);
          const [id, , lot, date] = value;

          let copyProducts = this.allProducts;
          let searchProductIndex = copyProducts.findIndex(
            (p) => p.product_id == id
          );
          //if found product
          if (searchProductIndex != -1) {
            this.found = true;
            successAudio.play();
            this.scrollToRow(searchProductIndex);
            this.product = copyProducts[searchProductIndex];
            let lotToUpdate = this.product.details.findIndex(function (p) {
              return p.lot === lot;
            });
            //if found existing lot, increase qty
            if (this.product.missing > 0)
              if (lotToUpdate != -1) {
                this.product.details[lotToUpdate].done_qty += 1;
                this.product.done_qty += 1;
                this.product.missing -= 1;
              } else {
                //add new lot
                let newLot = {
                  lot: lot,
                  ex_date: date.toString(),
                  done_qty: 1,
                };
                this.product.details.push(newLot);
                this.product.done_qty += 1;
                this.product.missing -= 1;
              }
            else {
              alert("Sản phẩm dư số lượng, sẽ được bỏ vào danh sách trả hàng");
              this.notFoundList.push(this.product);
            }
            this.$store.commit("Outbound/setProducts", copyProducts);
            this.search = "";
          } else {
            if (id) {
              alert("không tìm thấy sản phẩm: " + id);
              this.found = false;
              let notFoundObject = { id: id, name: name, done_qty: 1 };
              console.log(notFoundObject);
              let foundKey = this.notFoundList.findIndex((p) => p.id === id);
              if (foundKey != -1) this.notFoundList[foundKey].done_qty += 1;
              else this.notFoundList.push(notFoundObject);
            }
          }
          // this.found = true;
        } catch (e) {
          console.log(e);
          this.found = false;
        }
      } else {
        alert("Vui long nhap tu khoa tim kiem");
      }
    },
    actualQuantity(id) {
      let count = 0;
      let details = this.allProducts.filter((p) => p.id === id)[0].details;
      if (details) {
        details.forEach((item) => (count += item.done_qty));
      }
      return Number(count);
    },
  },
  computed: {
    ...mapGetters(["allProducts", "foundOrder"]),
    getProps: function () {
      if (this.hasFound === "ProductDetail") {
        return { pdetail: this.product };
      } else return "";
    },
    hasFound: function () {
      return this.found ? "ProductDetail" : "NotFound";
    },
    notFoundListColumns: function () {
      return [
        ...this.columns.slice(0, -1),
        { key: "return_count", label: "Số lượng" },
      ];
    },
    formatted: {
      get: function () {
        if (this.search.length > 0) {
          let detailArray = this.search.split(";");
          return detailArray[2]; // return product's id
        }
        return "";
      },
      set: function (val) {
        this.searchTerm = val;
      },
    },
  },
};
</script>

<style lang="scss">
#true-input {
  opacity: 100;
  max-width: 500px;
  min-height: 5px;
  display: inline;
}
#modal-btn {
  margin-left: 20px;
}
html,
body {
  // background-color: aquamarine;
  position: relative;
}

.cell {
  display: block;
  width: 100%;
  height: 100%;
  color: black;
}

.no-padding {
  padding: 0px !important;
}
.vgt-table.my-theme {
  padding: 0;
  & tr.clickable {
    & td {
      font-weight: bold;
    }
  }
  & tr.clickable:hover {
    & td {
      color: #0bd577;
    }
  }
}
.product-table {
  min-height: 70vh;
}
#Container {
  overflow: hidden;
  // background-color: #e6e8ef;
  margin-left: 0;
  margin-right: 0;
}
#Todos {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 0.5rem;
  height: 100%;
}
#ProductItem {
  border-radius: 05px;
  min-height: 70vh;
  background-color: #e6e8ef;
}
#outFinishBtn {
  font-size: 1.5em;
  border: none;
  margin-top: 20px;
  background-color: #0bd577;
  width: 57%;
  height: 5rem;
}

#outFinishBtn:hover {
  background-color: transparent;
  color: #0bd577;
  border: 2px solid #0bd577;
  border-radius: 2px;
}
#detail-info {
  padding: 0px;
  display: grid;
  object-fit: cover;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-gap: 0px 0px;
  // grid-template-columns: repeat(auto-fill, minmax);

  &__item:nth-child(1) {
    font-weight: bold;
    font-size: 2.5em;
    grid-row-start: 1;
    grid-row-end: 10;
    padding: 0 0 5px;
  }

  &__item {
    padding: 0.5em 1em;
  }
}
</style>

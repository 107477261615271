<template>
  <div>
    <app-header></app-header>
    <router-view></router-view>
  </div>
</template>

<script>
import header from "./components/Header";
export default {
  name: "App",
  components: {
    "app-header": header,
  },
  watch: {
    $route() {
      // clear alert on location change
      this.$store.dispatch("Alert/clear");
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
.red {
  & td {
    font-weight: bold;
    & span {
      color: #f67980;
    }
  }
}
.green {
  & td {
    font-weight: bold;
    & span {
      color: #0bd577;
    }
  }
}

.purple {
  & td {
    font-weight: bold;
    background-color: #4e75ff;
    & span {
      color: white !important;
    }
  }
}

.custom_active {
  background-color: #4e75ff !important;
}
</style>

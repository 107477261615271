// import axios from "axios";
import { getInbounds, getInboundByPO } from "@/api/inboundServices";
import { getInbound } from "../../api/inboundServices";
import axios from "axios";
const state = {
  ListOrder: [],
  Order: [],
  products: [],
  product: { name: "", body: "" },
  currentProduct: null,
};

const getters = {
  AllOrders: (state) => state.ListOrder,
  foundOrder: (state) => state.Order,
  allProducts: (state) => state.products,
  productById: (state) => (id) => {
    return state.products.filter((product) => product.id === id)[0];
  },
};

const actions = {
  async fetchProducts({ commit }, id) {
    await getInbound(id).then((res) => {
      let products = res.data.result.data.receipt;
      if (Object.keys(products).length > 0) {
        commit("setOrder", products);
        commit("setProducts", products.lines);
        let copySOList = JSON.parse(localStorage.getItem("PO"));
        copySOList.detail.push(id);
        localStorage.setItem("PO", JSON.stringify(copySOList));
      }
    });
  },
  async fetchOrderByPO({ dispatch, commit }, PO) {
    let result = null;
    try {
      result = await getInboundByPO(PO);
    } catch (err) {
      dispatch("Alert/error", "Lỗi: Không thể gửi yêu cầu tìm kiếm", {
        root: true,
      });
    }
    // if ()
    if (result && "data" in result) {
      if ("meta" in result.data.result && result.data.result.meta.status) {

        if (result.data.result.data.receipts.length > 0) {
          commit("setOrder", result.data.result.data.receipts);
          dispatch("Alert/success", "", {
            root: true,
          });
        } else {
          dispatch("Alert/error", "Lỗi:Không tìm thấy PO cần tìm", {
            root: true,
          });
        }
      } else
        dispatch("Alert/error", result.data.result.meta.message, {
          root: true,
        });
    } else console.log("Response data is null");
  },
  // async fetchListOrder({ commit }) {
  //   let result = null;
  //   try {
  //     result = await getInbounds();
  //   } catch (err) {
  //     alert("Error fetching orders: " + err);
  //   }
  //   if (result && "data" in result)
  //     commit("setListOrder", result.data.result.data.receipts);
  //   else console.log("Response data is null");
  // },
  async fetchPO({ commit }, id) {
    let res = await getInbounds();
    let PO = res.data.filter((data) => {
      return (
        data.ref.toLowerCase().includes(id.toLowerCase()) ||
        String(data.id).includes(id) ||
        data.partner.toLowerCase().includes(id.toLowerCase())
      );
    });
    commit("setOrder", PO);
  },
  //update current printing product's done_qty
  async updateCurrentProduct({ commit }) {
    let product = state.currentProduct;
    let copyAllProducts = state.products;
    if (product) {
      try {
        let done_qty = await axios.get(`${process.env.VUE_APP_BASE_PRINTER_URL}GetSensor/`);
        product.done_qty = parseInt(product.done_qty) + parseInt(done_qty.data.count);
        let productIndex = copyAllProducts.findIndex(function (p) {
          return p.id === product.id;
        });
        copyAllProducts[productIndex] = product;
        commit("setProducts", copyAllProducts);
      } catch (err) {
        throw new Error(err);
      }
    }
    else throw new Error("NULL_PRODUCT");
  },

  //update previous product's quantity
  async updatePreviousProductQuantity({ commit }, product) {
    //update being-printed product's quantity
    if (state.currentProduct != null) {
      let currentProduct = state.currentProduct;
      let copyAllProducts = state.products;
      let done_qty_request = await axios.get(
        "http://localhost:8080/GetSensor/"
      );
      let done_qty = done_qty_request.data.count;


      if (!currentProduct.done_qty)
        currentProduct.done_qty = done_qty ? parseInt(done_qty) : 0;
      else
        currentProduct.done_qty =
          parseInt(currentProduct.done_qty) + parseInt(done_qty);

      //update products array
      let productIndex = copyAllProducts.findIndex(function (p) {
        return p.id === currentProduct.id;
      });
      copyAllProducts[productIndex] = currentProduct;
      commit("setProducts", copyAllProducts);
    } else console.log("First item");
    // set new Current Product
    if (Object.keys(product).length > 0) commit("setCurrentProduct", product);
  },
};

const mutations = {
  setProducts: (state, products) => {
    state.Order.lines = products;
    state.products = products;
  },
  setOrder: (state, order) => (state.Order = order),
  setProduct: (state, product) => (state.product = product),
  setCurrentProduct: (state, currentProduct) =>
    (state.currentProduct = currentProduct),
  setListOrder: (state, Orders) => (state.ListOrder = Orders),
};

export const Inbound = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

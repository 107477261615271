<template>
  <div id="app" class="container">
    <b-row>
      <b-col> <h1 style="color: #0bd577">Sales Orders</h1></b-col>
    </b-row>
    <div class="dropdown">
      <b-form-input
        autofocus
        @keyup.enter="searchSO"
        ref="dropdowninput"
        v-model.trim="search"
        class="dropdown-input"
        type="text"
        placeholder="Nhập mã SO và nhấn Enter để tìm kiếm"
      />

      <div class="theme-container" v-if="!apiLoaded">
        <div class="text-center h1 py-5 text-black-50">
          <img
            src="@/assets/images/loading.gif"
            alt="Loading..."
            class="loading"
          />
        </div>
      </div>
      <div v-show="search && apiLoaded" class="dropdown-list">
        <div
          @click="onRowClick(item)"
          v-for="item in searchOrder"
          :key="item.ref"
          class="dropdown-item d-flex justify-content-between align-items-center"
        >
          <h4>{{ item.ref }}</h4>
          <span
            ><i>{{ item.date }}</i>
          </span>
        </div>
      </div>
    </div>
    <!-- <h4>SO đang kiểm</h4> -->
    <vue-good-table
      theme="my-theme"
      :row-style-class="rowStyleClassFn"
      v-if="onGoingOrders"
      :columns="fields"
      max-height="400px"
      styleClass="vgt-table"
      :rows="onGoingOrders"
      @on-row-click="onRowClick"
    >
      >
      <template slot="table-row" slot-scope="props">
        <span class="custom-cell" v-if="props.column.field == 'progress'">
          <span> {{ getProgress(props.row) }} </span>
          <span> &sol; {{ getTotal(props.row) }} </span>
        </span>
        <span v-if="props.column.field == 'status'">
          <span> Chưa cập nhật </span>
        </span>
        <span v-if="props.column.field == 'ref'">
          <span style="font-size: 15pt">
            {{ props.row.ref }}
          </span>
        </span>
        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
      </template>
      <template slot="table-column" slot-scope="props">
        <span>
          <span class="custom-header">
            {{ props.column.label }}
          </span>
        </span>
        <!-- <span>{{ props.formattedRow[props.column.field] }}</span> -->
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("Outbound");
// import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      Orders: [
        {
          date: "2020-05-24",
          id: 135149,
          name: "WH-C7/OUT/39724",
          partner: "Công Ty Cổ Phần Dược Phẩm Sohaco Miền Nam",
          ref: "SO31294",
        },
        {
          date: "2020-05-27",
          id: 130945,
          name: "WH-C7/OUT/39375",
          partner: "Công ty TNHH DKSH Việt Nam",
          ref: "SO31473",
        },
        {
          date: "2020-05-27",
          id: 133067,
          name: "WH-C7/OUT/39612",
          partner: "Quầy vật tư y tế Sen Việt - New",
          ref: "SO31524",
        },
      ],
      searchOrder: [],
      onGoingOrders: [],
      apiLoaded: true,
      fields: [
        { field: "id", label: "Id", width: "100px" },
        { field: "partner", label: "Partner", width: "300px" },

        {
          field: "ref",
          label: "Reference",
          width: "200px",
        },
        { field: "name", label: "SO", width: "200px" },
        { field: "date", label: "Date", width: "200px" },
        { field: "progress", label: "Progress" },
        { field: "status", label: "Tình trạng" },
      ],
      search: "",
    };
  },
  mounted() {
    
    if (!localStorage.getItem("SO")) {
      let username = JSON.parse(localStorage.getItem("user")).params.login;
      localStorage.setItem(
        "SO",
        JSON.stringify({ user: username, detail: [] })
      );
    } else {
      let SOList = JSON.parse(localStorage.getItem("SO"));
      
      SOList.detail.forEach((SO) => {
        let SODetail = JSON.parse(localStorage.getItem(SO));
      
        this.onGoingOrders.push(SODetail);
      });
    }
  },
  methods: {
    ...mapActions([
      "fetchProducts",
      "fetchPO",
      "fetchListOrder",
      "fetchOrderBySO",
    ]),
    searchSO() {
      if (this.search != "") {
        this.apiLoaded = false;
        this.fetchOrderBySO(this.search).then(() => {
          this.apiLoaded = true;
          if (this.foundOrder.length > 0) this.searchOrder = this.foundOrder;
          else alert("Không tìm thấy SO cần tìm");
        });
      } else alert("Vui lòng nhập mã phiếu hoặc mã SO cần tìm");
    },
    rowStyleClassFn(row) {
      return this.getProgress(row) == this.getTotal(row) ? "green" : "red";
    },
    updateSOList(newSOID) {
      let copySOList = JSON.parse(localStorage.getItem("SO"));
      copySOList.detail.push(newSOID);
      localStorage.setItem("SO", JSON.stringify(copySOList));
    },
    onRowClick(param) {
      
      //check which element calls the function: dropdown list or table row
      this.search = "row" in param ? param.row.id : param.id;
      // this.$router.push({ name: "OutProducts", params: { id: this.search } });
      //if not from row (user click from result list)
      // push Product to ongoing table
      if (!("row" in param)) {
        this.search = param.id;
        if (this.onGoingOrders.length == 0) {
          this.onGoingOrders.push(param);
          this.updateSOList(param.id);
          this.$router.push({
            name: "OutProducts",
            params: { id: this.search },
          });
          return;
        }
        let isOrderExisted = this.onGoingOrders.findIndex((O) => {
          return parseInt(O.id) === parseInt(this.search);
        });
        if (isOrderExisted == -1) {
          this.onGoingOrders.push(param);
          this.updateSOList(param.id);
          this.$router.push({
            name: "OutProducts",
            params: { id: this.search },
          });
        } else alert("SO này đã tồn tại trong bảng");
      } //If click from row, change to product page
      else
        this.$router.push({ name: "OutProducts", params: { id: this.search } });
    },
    updateItem(id) {
      this.product = this.productById(id);
    },
    getProgress(row) {
      let total_missing = 0;
      if (!localStorage.getItem(row.id)) {
        return -1;
      } else {
        let products = JSON.parse(localStorage.getItem(row.id)).lines;
        total_missing = products.reduce(
          (prev, next) => prev + next.done_qty,
          0
        );
        return total_missing;
      }
    },
    getTotal(row) {
      let total = 0;
      if (localStorage.getItem(row.id)) {
        let products = JSON.parse(localStorage.getItem(row.id)).lines;
        return products.reduce((prev, next) => prev + next.demand_qty, 0);
      }
      return total;
    },
  },
  computed: mapGetters(["foundOrder", "AllOrders"]),
};
</script>

<style scoped>
.red {
  color: #f67980;
}

.green {
  color: #0bd577;
}
.dropdown {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 10px auto;
}

.dropdown-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}

.dropdown-list {
  width: 100%;
  max-height: 300px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: #b6f2d6;
}
.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.custom-header {
  font-size: 1em;
}
.custom-cell {
  font-family: "Calibri";
  font-size: 1.5em;
  color: #0bd577;
}
</style>
